<template>
  <div class="chart-wrapper">
    <!-- <v-chart
      class="prediction-eChart"
      autoresize
      :option="predictionOption"
    ></v-chart> -->
    <img
      class="prediction-eChart"
      src="@/assets/image/ai/administer/chart_bg.png"
    />
    <span
      class="class-span"
      v-for="(item, index) in detail[mark].list"
      :key="index"
    >
      {{ item }}
    </span>
    <div class="chart-info">
      <span class="chart-title">趋势分析</span>
      <p class="chart-content">{{ detail[mark].analysis }}</p>
    </div>
  </div>
</template>

<script>
// import VChart, { THEME_KEY } from "vue-echarts";

export default {
  // provide: {
  //   [THEME_KEY]: "dark",
  // },
  // components: {
  //   VChart,
  // },
  props: {
    mark: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      detail: {
        sd: {
          analysis: "稻飞虱、水稻纹枯病占比较大，应提高防治措施",
          list: [
            "稻飞虱",
            "二化螟",
            "稻纵卷叶螟",
            "水稻纹枯病",
            "稻曲病",
            "稻瘟病",
          ],
        },
        dd: {
          analysis: "食心虫、根腐病占比较大，应提高防治措施",
          list: [
            "根腐病",
            "大豆蚜虫",
            "豆天蛾虫",
            "食心虫",
            "造桥虫",
            "象鼻虫",
          ],
        },
        dp: {
          analysis: "白粉虱、红蜘蛛占比较大，应提高防治措施",
          list: ["白粉虱", "茶黄螨", "温室蚜虫", "红蜘蛛", "潜叶蝇", "蓟马"],
        },
        xm: {
          analysis: "叶枯病、黑穗病占比较大，应提高防治措施",
          list: ["黑穗病", "纹枯病", "赤霉病", "叶枯病", "白粉病", "锈病"],
        },
      },
      // predictionOption: {
      //   backgroundColor: "transparent",
      //   radar: [
      //     {
      //       indicator: [
      //         { text: "稻纵卷叶螟" },
      //         { text: "稻虱" },
      //         { text: "二化螟" },
      //         { text: "稻曲病" },
      //         { text: "稻瘟病" },
      //         { text: "水稻纹枯病" },
      //       ],
      //       radius: [40, 70],
      //       splitNumber: 1,
      //       shape: "polygon",
      //       axisName: {
      //         color: "#fff",
      //         formatter: function (text) {
      //           text = text.replace(/(.{3})/g, "$&\n");
      //           return text;
      //         },
      //       },
      //       splitArea: {
      //         areaStyle: {
      //           color: {
      //             type: "radial",
      //             x: 0,
      //             y: 0,
      //             x2: 0,
      //             y2: 1,
      //             colorStops: [
      //               {
      //                 offset: 0,
      //                 color: "rgba(3, 0, 0, 0.08)",
      //               },
      //               {
      //                 offset: 1,
      //                 color: "rgba(1, 133, 226, 0.2)",
      //               },
      //             ],
      //           },
      //           shadowColor: "rgba(0, 0, 0, 0.5)",
      //           shadowBlur: 10,
      //         },
      //       },
      //       axisLine: {
      //         lineStyle: {
      //           color: "rgba(7, 118, 249, 0.4)",
      //         },
      //       },
      //       splitLine: {
      //         lineStyle: {
      //           color: "rgba(7, 118, 249, 1)",
      //         },
      //       },
      //     },
      //   ],
      //   series: [
      //     {
      //       type: "radar",
      //       emphasis: {
      //         lineStyle: {
      //           width: 0,
      //         },
      //       },
      //       symbol: "none",
      //       symbolSize: 0,
      //       data: [
      //         {
      //           value: [100, 8, 0.4, -80, 2000],
      //           name: "Data A",
      //           areaStyle: {
      //             color: {
      //               type: "linear",
      //               x: 0,
      //               y: 0,
      //               x2: 0,
      //               y2: 1,
      //               colorStops: [
      //                 {
      //                   offset: 0,
      //                   color: "rgba(44, 161, 194, .46)",
      //                 },
      //                 {
      //                   offset: 1,
      //                   color: "rgba(45, 124, 207, .46)",
      //                 },
      //               ],
      //             },
      //           },
      //         },
      //         {
      //           value: [60, 5, 0.3, -100, 1500],
      //           name: "Data B",
      //           areaStyle: {
      //             color: {
      //               type: "linear",
      //               x: 0,
      //               y: 0,
      //               x2: 0,
      //               y2: 1,
      //               colorStops: [
      //                 {
      //                   offset: 0,
      //                   color: "rgba(94, 142, 79, .46)",
      //                 },
      //                 {
      //                   offset: 1,
      //                   color: "rgba(55, 141, 96, .46)",
      //                 },
      //               ],
      //             },
      //           },
      //         },
      //       ],
      //     },
      //   ],
      // },
    };
  },
};
</script>

<style lang="less">
.chart-wrapper {
  width: 100%;
  height: 350px;
  position: relative;
  .prediction-eChart {
    width: 236px;
    // height: 280px;
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .class-span {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
    position: absolute;
    &:nth-of-type(1) {
      top: 110px;
      left: -30px;
    }
    &:nth-of-type(2) {
      top: 220px;
      left: -30px;
    }
    &:nth-of-type(3) {
      top: 45px;
      left: 105px;
    }
    &:nth-of-type(4) {
      top: 285px;
      left: 105px;
    }
    &:nth-of-type(5) {
      top: 110px;
      right: -30px;
    }
    &:nth-of-type(6) {
      top: 220px;
      right: -30px;
    }
  }
  .chart-info {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .chart-title {
      font-size: 16px;
      font-weight: 400;
      color: #f7c35c;
      margin-bottom: 8px;
    }
    .chart-content {
      width: 50%;
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      text-align: center;
    }
  }
}
</style>
